import React from "react";

const Card = ({
  label,
  bgColor = "bg-h-flash",
  children,
  extraClass,
  onClick,
}) => {
  return (
    <div
      style={{
        borderRadius: "30px",
        cursor: onClick ? "pointer" : "default",
      }}
      className={`relative ${extraClass} ${bgColor} p-8 text-h-blue border-2 border-h-blue font-semibold shadow-btn hover:cursor-pointer duration-200 transition-shadow ease-in-out`}
      onClick={onClick}
    >
      {children}

      <div
        className={`${
          onClick ? "visible" : "hidden"
        } absolute bottom-10 right-0 mr-5`}
      >
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 10L10 1"
            stroke="#07005C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 1H10V10"
            stroke="#07005C"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default Card;
