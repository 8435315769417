import React from "react";
import { Users, Zap, Heart, Crosshair } from "react-feather";
import Title from "../components/Title";

const lists = [
  {
    title: "Accompagnement",
    icon: <Users className="h-6 w-6 text-h-purple" />,
    description:
      "Notre équipe vous conseille durant toutes les étapes de votre projet et au-delà. Nous nous assurons du bon fonctionnement de votre site web ou/et de votre application mobile mais également de sa rentabilité sur le long terme avec nos services de maintenance et de marketing.",
  },
  {
    title: "Rigueur",
    icon: <Crosshair className="h-6 w-6 text-h-purple" />,
    description:
      "Nous mettons à votre disposition de nombreuses compétences métiers pour répondre à tous vos besoins pour vos projets. Développement web et mobile, design, marketing, nous couvrons toutes les étapes de production de la conception jusqu’à la livraison.",
  },
  {
    title: "Confiance",
    icon: <Heart className="h-6 w-6 text-h-purple" />,
    description:
      "Nous souhaitons créer une relation de confiance avec tous nos clients, c’est pourquoi nous sommes totalement transparents sur notre manière de travailler avec vous. Adeptes de la communication, nous prônons de nombreux échanges pour être sûrs de toujours bien répondre à vos besoins.",
  },
  {
    title: "Performance",
    icon: <Zap className="h-6 w-6 text-h-purple" />,
    description:
      "Nous réalisons de nombreux tests pour vous délivrer un produit fonctionnel et efficace. En plus de cela, nous assurons le suivi et la maintenance pour que votre site web ou votre application mobile demeure performant(e) sur le long terme.",
  },
];

const Visions = () => (
  <div className="container mx-auto px-4">
    <Title subTitle="L'esprit de Hulli" title="Notre vision" />

    <div className="mt-10">
      <ul className="md:grid md:grid-cols-2 gap-16 md:col-gap-16 md:row-gap-10">
        {lists.map((data, i) => (
          <div key={i} className="flex mb-10">
            <div>
              <h4 className="text-lg font-semibold text-h-blue leading-6 text-secondary inline-flex">
                <div className="w-10">{data.icon}</div>
                {data.title}
              </h4>

              <p className="mt-3 text-base leading-8 text-h-blue">
                {data.description}
              </p>
            </div>
          </div>
        ))}
      </ul>
    </div>
  </div>
);

export default Visions;
