import React from "react";

const Menu = () => (
  <nav className="flex items-center justify-between w-full px-4 lg:px-0">
    <a className="text-lg font-medium text-white lg:w-auto" href="/">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        viewBox="0 0 1319.89 1336.09"
      >
        <path
          fill="#000143"
          d="M597.82.18h0c-278.92,0-505,226.11-505,505v817a13.67,13.67,0,0,0,13.67,13.67h0c278.93,0,505-226.11,505-505v-817A13.67,13.67,0,0,0,597.82.18Z"
        />
        <path
          fill="#000143"
          d="M1213.44.18h0c-278.92,0-505,226.11-505,505v817a13.67,13.67,0,0,0,13.66,13.67h0c278.93,0,505-226.11,505-505v-817A13.67,13.67,0,0,0,1213.44.18Z"
        />
      </svg>
    </a>

    <div className="hidden md:flex justify-center lg:justify-end items-center">
      {[
        // {
        //   label: "Nos réalisations",
        //   link: "/realisations",
        // },
        // {
        //   label: "À propos",
        //   link: "/a-propos",
        // },
      ].map((link) => (
        <a
          key={link.label}
          href={link.link}
          className="flex rounded-full p-3 px-5 ml-5 text-h-blue font-semibold hover:text-opacity-50 hover:cursor-pointer transition duration-200 ease-in-out"
        >
          {link.label}
        </a>
      ))}

      <a
        href="https://calendly.com/hulli-studio/20min"
        target="_blank"
        rel="noopener noreferrer"
        className="flex bg-white rounded-full p-6 px-10 ml-5 text-h-blue border-2 border-h-blue font-semibold shadow-btn hover:cursor-pointer transition duration-200 ease-in-out"
      >
        Prendre un rendez-vous
      </a>
    </div>

    <div className="flex md:hidden">
      <a
        href="https://calendly.com/hulli-studio/20min"
        target="_blank"
        rel="noopener noreferrer"
        className="flex bg-white rounded-full p-6 px-8 text-h-blue border-2 border-h-blue font-semibold shadow-btn hover:cursor-pointer transition duration-200 ease-in-out"
      >
        Prendre un rendez-vous
      </a>
    </div>
  </nav>
);

export default Menu;
