import React, { useMemo } from "react";
import Marquee from "react-fast-marquee";

import { ReactComponent as DiagorienteLogo } from "../assets/clients/diagoriente.svg";
import { ReactComponent as AltgencyLogo } from "../assets/clients/altgency.svg";
import { ReactComponent as LyveatLogo } from "../assets/clients/lyveat.svg";
import { ReactComponent as NomosTechLogo } from "../assets/clients/nomostech.svg";
import { ReactComponent as NotiplusLogo } from "../assets/clients/notiplus.svg";
import { ReactComponent as EngieLogo } from "../assets/clients/engie.svg";
import { useBreakpoint } from "../hooks";

const ITEMS = [
  {
    name: "Lyveat",
    logo: <LyveatLogo />,
  },
  {
    name: "Notiplus",
    logo: <NotiplusLogo />,
  },
  {
    name: "NomosTech",
    logo: <NomosTechLogo />,
  },
  {
    name: "Engie",
    logo: <EngieLogo />,
  },
  {
    name: "Altgency",
    logo: <AltgencyLogo />,
  },
  {
    name: "Diagoriente",
    logo: <DiagorienteLogo />,
  },
];

const Clients = () => {
  const isDesktop = useBreakpoint("md");

  const gradientWidth = useMemo(() => (isDesktop ? 600 : 100), [isDesktop]);

  return (
    <div className="bg-white mt-12">
      <Marquee speed={60} gradientWidth={gradientWidth}>
        <div className={"flex flex-auto flex-row items-center justify-around"}>
          {ITEMS.map((item) => (
            <div
              key={item.name}
              className="flex mx-6 md:mx-0 justify-center items-center col-span-1 md:col-span-2 lg:col-span-1"
            >
              {item.logo}
            </div>
          ))}
        </div>
      </Marquee>
    </div>
  );
};

export default Clients;
