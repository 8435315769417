import React from "react";
import PropTypes from "prop-types";

const Button = (
  { variant, type, size, disabled, active, onChange, children },
  props
) => {
  const btnVariants = {
    default: `text-white bg-primary focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo`,
    outline:
      "flex bg-white rounded-full p-6 px-10 ml-5 text-h-blue border-2 border-h-blue font-semibold hover:cursor-pointer transition duration-200 transition-shadow ease-in-out",
    // outline: `text-primary bg-transparent bg focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo`,
    link: `text-h-blue`,
  };

  return (
    <button
      type={type || "button"}
      className={` font-sans font-bold text-h-blue transition ease-in-out duration-150`}
      onChange={onChange}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.string,
  type: PropTypes.string,
  buttonStyle: PropTypes.oneOf(["fill", "outline", "text", "link"]),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["tiny", "small", "large"]),
};

Button.defaultProps = {
  children: "Valider",
  type: "button",
  buttonStyle: "fill",
  disabled: false,
  size: "default",
};

export default Button;
