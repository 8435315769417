import React from "react";
import {
  Menu,
  Banner,
  Clients,
  Team,
  Why,
  Footer,
  Posts,
  Visions,
} from "./sections";

const App = () => {
  return (
    <div>
      <div className="bg-h-beige">
        <div className="container mx-auto pt-16">
          <Menu />
        </div>

        <div className="container bg-h-beige mx-auto py-24 md:py-48">
          <Banner />
        </div>
      </div>

      <Clients />

      <div className="container mx-auto pt-32 pb-24">
        <Why />
      </div>

      <div className="container mx-auto py-24">
        <Visions />
      </div>

      <div className="container mx-auto py-24">
        <Team />
      </div>

      {/* <div className="container mx-auto py-24">
        <Posts />
      </div> */}

      <div className="bg-h-beige">
        <div className="container mx-auto py-24">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default App;
