import React from "react";

const Title = ({ subTitle, title, description }) => {
  return (
    <div className="mb-8 md:mb-16">
      {subTitle && (
        <p
          className={`font-sans leading-6 text-h-blue-flash font-semibold tracking-wide uppercase`}
        >
          {subTitle}
        </p>
      )}
      <h3
        className={`font-sans tracking-wide mt-2 text-3xl leading-normal font-bold text-h-blue sm:text-4xl sm:leading-10`}
      >
        {title}
      </h3>
    </div>
  );
};

export default Title;
