import { useState, useEffect } from "react";

/**
 * example:
 * const isLargeOrMore = useMediaQuery("(min-width: 1024px)")
 */
export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(
    typeof window === "undefined" ? false : window.matchMedia(query).matches
  );

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);

    window.addEventListener("resize", listener);

    return () => window.removeEventListener("resize", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
};
