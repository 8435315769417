import React from "react";

const ReactSvg = () => (
  <svg
    width="73"
    height="64"
    viewBox="0 0 73 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_705_73)">
      <path
        d="M36.5 38.4114C40.0934 38.4114 43.0065 35.5409 43.0065 32C43.0065 28.4591 40.0934 25.5886 36.5 25.5886C32.9065 25.5886 29.9935 28.4591 29.9935 32C29.9935 35.5409 32.9065 38.4114 36.5 38.4114Z"
        fill="#08D4AE"
      />
      <path
        d="M36.5 45.1356C55.782 45.1356 71.4131 39.2546 71.4131 32C71.4131 24.7454 55.782 18.8644 36.5 18.8644C17.2181 18.8644 1.58698 24.7454 1.58698 32C1.58698 39.2546 17.2181 45.1356 36.5 45.1356Z"
        stroke="#08D4AE"
        strokeOpacity="0.4"
      />
      <path
        d="M24.9555 38.5678C34.5965 55.0224 47.5807 65.421 53.9566 61.7937C60.3324 58.1664 57.6855 41.8868 48.0445 25.4322C38.4036 8.97762 25.4194 -1.42095 19.0435 2.20635C12.6677 5.83364 15.3146 22.1132 24.9555 38.5678Z"
        stroke="#08D4AE"
        strokeOpacity="0.4"
      />
      <path
        d="M24.9555 25.4322C15.3146 41.8868 12.6677 58.1664 19.0435 61.7937C25.4194 65.421 38.4035 55.0224 48.0445 38.5678C57.6855 22.1132 60.3324 5.83364 53.9565 2.20634C47.5807 -1.42095 34.5965 8.97762 24.9555 25.4322Z"
        stroke="#08D4AE"
        strokeOpacity="0.4"
      />
    </g>
    <defs>
      <clipPath id="clip0_705_73">
        <rect width="73" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Banner = () => {
  return (
    <h2 className="mb-16 font-sans text-4xl font-extrabold tracking-wide leading-relaxed text-h-blue mx-6 md:mx-0 xl:text-5xl md:text-5xl xs:text-3xl">
      Apportez l’idée, nous la développons grâce à{" "}
      <br className="hidden xl:block" />
      notre{" "}
      <span className="text-h-green">
        expertise en React.js & React Native
        <span className="relative">
          <div
            className="hidden md:flex absolute animate-spin"
            style={{
              position: "absolute",
              left: "-20px",
              top: "0px",
            }}
          >
            <ReactSvg />
          </div>
        </span>
      </span>
    </h2>
  );
};
export default Banner;
