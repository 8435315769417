import React from "react";

const Footer = () => (
  <div className="mx-4">
    <div className="grid grid-cols-1 lg:grid-cols-6 gap-16 items-center self-center">
      <div className="lg:col-span-3 space-y-6">
        <a className="text-lg font-medium text-white lg:w-auto" href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            viewBox="0 0 1319.89 1336.09"
          >
            <path
              fill="#000143"
              d="M597.82.18h0c-278.92,0-505,226.11-505,505v817a13.67,13.67,0,0,0,13.67,13.67h0c278.93,0,505-226.11,505-505v-817A13.67,13.67,0,0,0,597.82.18Z"
            />
            <path
              fill="#000143"
              d="M1213.44.18h0c-278.92,0-505,226.11-505,505v817a13.67,13.67,0,0,0,13.66,13.67h0c278.93,0,505-226.11,505-505v-817A13.67,13.67,0,0,0,1213.44.18Z"
            />
          </svg>
        </a>

        <div>
          {/* <h3 className="mt-2 text-xl leading-7 font-semibold text-h-blue">
          Hulli Studio
        </h3>
    */}
          <p class="text-h-blue mt-12">
            <strong>
              Studio digital, spécialisé dans la création d'applications mobile
              et de sites web.
            </strong>{" "}
            Si vous êtes prêt à faire évoluer votre marque et votre entreprise,{" "}
            <a
              className="font-semibold hover:text-opacity-50 hover:cursor-pointer transition duration-200 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
              href="https://calendly.com/hulli-studio/20min"
            >
              contactez-nous
            </a>
            .
          </p>
        </div>
      </div>

      <div className="lg:col-span-3">
        <div className="grid grid-cols-2 mt-8">
          <div>
            {/* <h3 className="text-h-purple mb-4 uppercase text-sm">Entreprise</h3>

            <ul className="space-y-2 mt-8">
              <li>
                <a
                  className="text-h-blue font-semibold hover:text-opacity-50 hover:cursor-pointer transition duration-200 ease-in-out"
                  href="/"
                >
                  À propos
                </a>
              </li>

              <li>
                <a
                  className="text-h-blue font-semibold hover:text-opacity-50 hover:cursor-pointer transition duration-200 ease-in-out"
                  href="/"
                >
                  Mentions légales
                </a>
              </li>
            </ul> */}
          </div>

          <div>
            <h3 className="text-h-purple mb-4 uppercase text-sm">
              SUIVEZ-NOUS
            </h3>

            <ul className="space-y-2 mt-8">
              <li>
                <a
                  className="text-h-blue font-semibold hover:text-opacity-50 hover:cursor-pointer transition duration-200 ease-in-out"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/hulli.studio"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  className="text-h-blue font-semibold hover:text-opacity-50 hover:cursor-pointer transition duration-200 ease-in-out"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/hulli-studio"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  className="text-h-blue font-semibold hover:text-opacity-50 hover:cursor-pointer transition duration-200 ease-in-out"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dribbble.com/hullistudio"
                >
                  Dribbble
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <p className="mt-16 text-h-blue">© 2022 • Tous droits réservés.</p>
  </div>
);

export default Footer;
