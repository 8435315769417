import React from "react";

import { ReactComponent as FranceSvg } from "../assets/France.svg";
import { ReactComponent as UpSvg } from "../assets/up.svg";
import Title from "../components/Title";
import Button from "../components/Button";

const Team = () => {
  return (
    <div className="grid md:gap-8 grid-cols-1 lg:grid-cols-2">
      <div className="hidden lg:flex m-8 md:m-0">
        <FranceSvg width="100%" />
      </div>

      <div className="relative mx-6 self-center">
        <Title subTitle="NOTRE ÉQUIPE" title="Les meilleurs développeurs" />

        <p className="font-sans text-h-blue">
          <strong>
            HULLI est bien plus qu'une simple agence de conception de produits.
          </strong>{" "}
          Nous sommes un partenaire de confiance pour les entreprises en
          croissance qui cherchent à créer les meilleurs sites et applications
          mobiles pour leur activité. <br />
          <br /> Faites confiance à notre équipe de développeurs expérimentés
          pour créer des produits qui stimuleront votre activité et vous
          aideront à atteindre vos objectifs.
        </p>

        <div className="absolute right-0  mt-8">
          <UpSvg />
        </div>

        <div className="mt-8 relative flex">
          <a
            className="font-bold"
            href="https://calendly.com/hulli-studio/20min"
            target="_blank"
            rel="noopener noreferrer"
          >
            Choisir notre équipe !
            <div className="h-2 w-full bg-h-blue-light bg-opacity-50 -mt-2" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Team;
