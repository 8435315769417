import React from "react";

import Card from "../components/Card";
import Badge from "../components/Badge";
import Title from "../components/Title";
import Button from "../components/Button";

const COMPETENCES = [
  { label: "TypeScript" },
  { label: "React.js" },
  { label: "Expo" },
  { label: "React Native" },
  { label: "Nest.js", bullShit: true },
  { label: "Node.js" },
  { label: "Laravel", bullShit: true },
  { label: "Firebase", bullShit: true },
  { label: "Symfony", bullShit: true },
];

const Why = () => {
  const [bullShit, setBullShit] = React.useState(true);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-12 gap-12 mx-6">
      <div className="md:col-span-5 relative mx-6 self-center">
        <Title
          subTitle="Pourquoi HULLI STUDIO ?"
          title="Les entreprises en quête de résultats font appel à nous !"
        />

        <p className="text-h-blue">
          <strong>Hulli Studio</strong>, c'est avant tout des développeurs
          experts React.js & React Native mettant un point d'honneur sur
          l'agilité, la qualité du code et la mise en production des projets !
          <br />
          <br />
          {/* <strong>
            Nous ne pouvons pas accumuler les missions toute en faisant de la
            qualité. "no-code", "yield", "growth" ne suffisent pas pour réaliser
            des projets ambitieux et qualitatif.
          </strong> */}
        </p>
        <div className="flex  relative">
          <a
            variant="link"
            href="https://calendly.com/hulli-studio/20min"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold"
          >
            En savoir plus sur nous
            <div className="h-2 w-full bg-h-blue-light bg-opacity-50 -mt-2" />
          </a>
        </div>
      </div>

      <div className="md:col-span-7">
        <div className="grid grid-cols-1 md:grid-cols-5 md:gap-4">
          <div className="col-span-3 space-y-6 gap-4">
            <Card bgColor="bg-h-green">
              <div className="space-y-8">
                <h2 className="font-bold font-sans text-2xl">
                  Ce que nous maitrisons !
                </h2>

                <div className="flex flex-wrap gap-2">
                  {COMPETENCES.map((item, index) => (
                    <div key={index} className="inline-flex">
                      <Badge
                        key={index}
                        label={item.label}
                        bgColor="bg-h-blue-flash"
                        opacity={!bullShit ? (item.bullShit ? 50 : 100) : 0}
                      />
                    </div>
                  ))}
                </div>

                <div className="inline-flex gap-4 items-center">
                  <div
                    style={{
                      height: "30px",
                      width: "50px",
                      justifyContent: bullShit ? "right" : "left",
                    }}
                    className="flex items-center rounded-full border border-h-blue bg-white cursor-pointer"
                    onClick={() => setBullShit(!bullShit)}
                  >
                    <div className="mx-1 h-5 w-5 bg-h-blue-flash border border-h-blue rounded-full " />
                  </div>

                  <span>BullShit</span>
                </div>
              </div>
            </Card>

            <div className="float-right w-full md:w-64">
              <Card
                onClick={() =>
                  window.open("https://linkedin.com/in/brandonsueur", "_blank")
                }
                bgColor="bg-h-purple"
              >
                <div className="space-y-8">
                  <h2 className="font-bold font-sans text-2xl text-white">
                    Le fondateur c'est un dév !
                  </h2>

                  <div className="inline-flex gap-4 items-center">
                    <img
                      src={"/me.jpg"}
                      width="50px"
                      height="50px"
                      alt="Site de Brandon Sueur — Expert React.js & React Native"
                      className="rounded-full"
                    />

                    <span className="text-white">Brandon S.</span>
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <div className="col-span-2 self-center mt-6 md:mt-0">
            <Card bgColor="bg-h-yellow">
              <div className="space-y-6">
                <h2 className="font-bold font-sans text-2xl">
                  +10
                  <br className="hidden sm:visible md:hidden" /> Dévelo
                  <span className="hidden sm:visible md:hidden">-</span>
                  ppeurs
                </h2>

                <p className="text-lg">
                  compétents pour développer les sites & applications !
                </p>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
