import React from "react";
import { Monitor, Edit2, LifeBuoy } from "react-feather";
import Title from "../components/Title";

const lists = [
  {
    title: "Conception",
    icon: <Edit2 className="h-6 w-6" />,
    description:
      "Nos créations incarnent l'essence de votre marque pour rehausser votre représentation numérique. Nous sommes bien familiarisés avec la création d'une expérience utilisateur améliorée avec des interfaces impressionnantes assorties.",
    uls: [
      "Sprints de conception",
      "Conception UI / UX",
      "Conception mobile et Web",
    ],
  },
  {
    title: "Développement",
    icon: <Monitor className="h-6 w-6" />,
    description:
      "Nous sommes spécialisé sur le développement web et mobile en utilisant React.js, React Native et Expo. Notre équipe crée des sites web et des applications mobile avec une expérience utilisateur engageante.",
    uls: [
      "Laravel / Symfony / Node.js",
      "React.js / Vue.js",
      "React Native / Expo",
    ],
  },
  {
    title: "Soutien",
    icon: <LifeBuoy className="h-6 w-6" />,
    description:
      "Combler une lacune de compétences dans votre équipe de développement existante, maintenir le code ou consulter vos besoins numériques. Nous fournissons une gamme de services d'assistance pour vous garder au-dessus du bord.",
    uls: [
      "Renfort d'une équipe",
      "Maintenance du code",
      "Assistance au démarrage",
    ],
  },
];

const Services = () => (
  <div className="container mx-auto">
    <Title
      subTitle="Nécessaire pour une entreprise numérique prospère"
      title="Prestations de service"
    />

    <div class="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
      {lists.map((list, i) => (
        <div key={i}>
          <h3 class="text-lg leading-6 font-medium text-secondary inline-flex">
            <div className="w-10">{list.icon}</div>
            {list.title}
          </h3>
          <p class="mt-3 text-base leading-8 text-white">{list.description}</p>

          {/* <ul className="list-disc list-inside mt-8">
            {list.uls.map((string) => (
              <li className="text-base leading-8 text-white">{string}</li>
            ))}
          </ul> */}
        </div>
      ))}
    </div>
  </div>
);

export default Services;
