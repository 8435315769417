import { useMediaQuery } from "./useMediaQuery";

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

/**
 * example:
 * const isSmall = useBreakpoint("sm") // >= 640px
 */
export const useBreakpoint = (key) =>
  useMediaQuery(`(min-width: ${breakpoints[key]}px)`);
