import React from "react";

const Badge = ({ label, bgColor = "bg-h-flash", opacity }) => {
  return (
    <div
      style={{
        opacity: `${opacity || 100}%`,
      }}
      className={`${bgColor} relative rounded-full border-2 border-h-blue py-2 px-4 transition duration-200 ease-in-out`}
    >
      {opacity && label === "React.js" ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="transparent"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute -mt-5 right-0 transform rotate-45"
        >
          <path
            d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
            stroke="#FFEA83"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      ) : null}
      <span className="font-sans font-semibold text-white">{label}</span>
    </div>
  );
};

export default Badge;
